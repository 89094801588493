<template>

  <!-- Promotion -->
  <div id="story-mode-promotion">

    <!-- Main Navigation -->
    <main-navigation />
    <!-- /Main Navigation -->

    <!-- Container -->
    <div class="container-1200">

      <!-- Content -->
      <div
        class="content"
        :class="{ 'position-relative': statusOfCompleted }"
      >
        <v-app>
          <!-- Header -->
          <header class="page-header">

            <!-- Dashboard Header -->
            <dashboard-header
              :class="{ 'stamp': statusOfCompleted }"
              :title="$t('steps.concept.items.promotion')"
              :guide-visible="guideVisible"
              :guide-visible-first-time="guideVisibleFirstTime"
              :completed-step-name="'promotionCompleted'"
              hide-quick-tour-button
              @toggle-guide="onGuideExampleVisible"
              @open-comp-dialog="openedDialogProcessCompleted"
              @open-info="openInfo"
              @open-ai-buddy-drawer="aiBuddyDrawerVisible = true"
            />
            <!-- /Dashboard Header -->

            <!-- Divider -->
            <hr>
            <!-- /Divider -->

          </header>
          <!-- /Header -->

          <!-- Info Guide Expand Panel -->
          <info-guide
            v-if="guideVisibleFirstTime"
            class="mb-5"
            type="promotionExamples"
            visible
            @click="guideVisibleFirstTime = !guideVisibleFirstTime"
          />
          <!-- Info Guide Expand Panel -->

          <!-- Ai Buddy Drawer -->
          <ai-buddy-drawer
            :visible="aiBuddyDrawerVisible"
            section-name="Promotion"
            help-me-write
            type="promotion"
            @close="aiBuddyDrawerVisible = false"
          />
          <!-- /Ai Buddy Drawer -->
        </v-app>

        <!-- Business plan additional info -->
        <business-plan-additional-info
          completed-step-name="promotionCompleted"
          section-name="Promotion"
          show-ai-buddy
          @ai-buddy-open="aiBuddyDrawerVisible = true"
        />
        <!-- /Business plan additional info -->

        <!-- Height Holder For Sticky Header -->
        <div class="height-holder d-none d-md-block" />
        <!-- /Height Holder For Sticky Header -->

        <!-- Title & View Switcher -->
        <div class="proposition marketing">
          <v-app>
            <!-- Header -->
            <business-guide-header>
              <template #left>
                <h2 class="mt-md-6">
                  {{ $t('pages.businessGuide.promotion.promotionalActivities') }}
                </h2>
                <p class="text-lead">
                  {{ $t('pages.businessGuide.promotion.defineTheWays') }}
                </p>
              </template>
              <template #right>
                <erase-icon-button
                  v-if="removeButtonVisible"
                  @click="onEraseFromTemplate"
                />

                <business-guide-search
                  v-if="!isPromotionalActivitiesEmpty"
                  v-model="searchValue"
                  class="mx-1"
                />
                <!-- View Switcher -->
                <view-switcher
                  v-if="isPromotionalActivitiesEmpty === false"
                  :active-view="$store.state.itemsView.promotionView"
                  @activate-view="onActivateView"
                />
                <!-- /View Switcher -->
              </template>
            </business-guide-header>
            <!-- /Header -->
          </v-app>
        </div>
        <!-- /Title & View Switcher -->

        <!-- Promotion -->
        <div class="promotion">

          <!-- Grid Wrapper -->
          <div class="grid-wrapper" :class="{ 'active': $store.state.itemsView.promotionView === 'grid' }">
            <el-row :gutter="20">
              <!-- Completed overlay -->
              <completed-overlay
                v-if="hasNotAccess"
                @click="checkPromotionCardVisible"
              />
              <!-- /Completed overlay -->

              <div
                v-if="isPromotionalActivitiesEmpty"
                class="d-flex flex-wrap"
              >
                <el-col :md="8" class="d-flex width-xl-20 mb-3">
                  <add-new-card
                    class="width-xl-20"
                    :title="$tc('addPromotionalActivity')"
                    :disabled="hasNotAccess"
                    @click="checkPromotionCardVisible"
                  />
                </el-col>

                <!-- Placeholder 1 -->
                <el-col v-if="$breakpoint.mdAndUp" :md="8" class="width-lg-20 mb-3">
                  <div class="placeholder-card large">
                    <div class="w-100">
                      <div class="avatar-placeholder blue" />
                      <div class="stripe-medium" />
                      <div class="stripe-small" />
                      <div class="stripe-large" />
                    </div>
                  </div>
                </el-col>
                <!-- /Placeholder 1 -->

                <!-- Placeholder 2 & Tutorial Tooltip -->
                <el-col v-if="$breakpoint.mdAndUp" :md="8" class="width-lg-20 mb-3">
                  <div class="placeholder-card large">

                    <!-- Placeholder 2 -->
                    <div class="w-100">
                      <div class="avatar-placeholder yellow" />
                      <div class="stripe-medium" />
                      <div class="stripe-small" />
                      <div class="stripe-large" />
                    </div>
                    <!-- /Placeholder 2 -->

                    <!-- Tutorial Tooltip TODO - Currently disabled in if directive -->
                    <tutorial-tooltip
                      v-if="$store.state.user.tutorial.promotionCompleted === false && 1 === 2"
                      :title="$t('pages.businessGuide.promotion.addNewPromotionalActivity')"
                      :text="$t('pages.businessGuide.thisIsStarOfYourShow')"
                      :tutorial-name="'promotionCompleted'"
                    />
                    <!-- /Tutorial Tooltip -->
                  </div>
                </el-col>
                <!-- /Placeholder 2  & Tutorial Tooltip -->
              </div>

              <!-- Promotion Blocks -->
              <ib-draggable
                v-if="!isPromotionalActivitiesEmpty"
                class="d-flex flex-wrap flex-grow-1"
                :list="promotionalActivitiesToShowGrid"
                :options="{disabled: hasNotAccess}"
                @change="onChangePromotionOrder"
                @start="onStartPromotionOrder"
              >
                <template #header>
                  <el-col :md="8" class="d-flex width-xl-20 mb-3">
                    <add-new-card
                      class="width-xl-20"
                      :title="$tc('addPromotionalActivity')"
                      :disabled="hasNotAccess"
                      @click="checkPromotionCardVisible"
                    />
                  </el-col>
                </template>
                <el-col
                  v-for="promotionalActivity in promotionalActivitiesToShowGrid"
                  :key="promotionalActivity.id"
                  class="d-flex width-lg-20 draggable mb-3"
                  :md="8"
                >

                  <div
                    class="product-card w-100 cursor-move"
                    :class="promotionalActivity.type"
                    @click="checkPromotionCard(promotionalActivity)"
                  >
                    <!-- Card Top -->
                    <div
                      class="card-top"
                      :class="{ 'default': !promotionalActivity.image }"
                      :style="cardImageBySize(promotionalActivity.image, 'medium')"
                    >

                      <!-- Actions Dropdown -->
                      <actions-dropdown
                        v-if="canEdit && !statusOfCompleted"
                        ref="actionsDropdown"
                        :entity="promotionalActivity"
                        @edit="onEditPromotionalActivity"
                        @copy="onCopyPromotionalActivity"
                        @delete="onDeletePromotionalActivity"
                      />
                    <!-- /Actions Dropdown -->

                    </div>
                    <!-- /Card Top -->

                    <!-- Card Bottom -->
                    <div class="card-bottom">

                      <!-- Title -->
                      <h3>{{ promotionalActivity.name }}</h3>
                      <!-- /Title -->

                      <!-- Description -->
                      <p class="pre-formatted text-left ml-2 mr-2">
                        {{ promotionalActivity.description }}
                      </p>
                    <!-- /Description -->

                    </div>
                  <!-- /Card Bottom -->

                  </div>
                </el-col>
              </ib-draggable>
              <!-- /Promotion Blocks -->
            </el-row>
            <!-- Show All -->
            <el-row v-if="promotions.length > 4">
              <el-col>
                <show-all-toggle
                  :show="showAllPromotionalActivitiesGrid"
                  @click="showAllPromotionalActivitiesGrid = !showAllPromotionalActivitiesGrid"
                />
              </el-col>
            </el-row>
            <!-- /Show All -->
          </div>
          <!-- /Grid Wrapper -->

          <!-- List Wrapper -->
          <div class="list-wrapper" :class="{ 'active': $store.state.itemsView.promotionView === 'list' }">
            <el-row :gutter="20">
              <!-- Completed overlay -->
              <completed-overlay
                v-if="hasNotAccess"
                @click="checkPromotionCardVisible"
              />
              <!-- /Completed overlay -->

              <ib-draggable
                class="d-flex flex-wrap flex-grow-1"
                :list="promotionalActivitiesToShowList"
                :options="{disabled: hasNotAccess}"
                @change="onChangePromotionOrder"
                @start="onStartPromotionOrder"
              >
                <template #header>
                  <el-col :md="12" class="d-flex mb-3">
                    <add-new-card
                      class="width-xl-20"
                      :title="$tc('addPromotionalActivity')"
                      :disabled="hasNotAccess"
                      horizontal
                      @click="checkPromotionCardVisible"
                    />
                  </el-col>
                </template>
                <!-- List Items -->
                <el-col
                  v-for="promotionalActivity in promotionalActivitiesToShowList"
                  :key="promotionalActivity.id"
                  :md="12"
                  class="draggable mb-3"
                >
                  <div
                    class="promotion-card cursor-move"
                    :class="promotionalActivity.type"
                    @click="checkPromotionCard(promotionalActivity)"
                  >

                    <!-- Card Top -->
                    <div class="card-top">

                      <!-- Card Image & Promotional Activity Name -->
                      <div class="flex-inner">

                        <!-- Card Image -->
                        <div>
                          <div
                            class="card-image-round" :class="{ 'default': !promotionalActivity.image }"
                            :style="cardImageBySize(promotionalActivity.image, 'small')"
                          />
                        </div>
                        <!-- /Card Image -->

                        <!-- Promotional Activity Name -->
                        <h3>{{ promotionalActivity.name }}</h3>
                      <!-- /Promotional Activity Name -->

                      </div>
                      <!-- /Card Image & Promotional Activity Name -->

                      <!-- Actions Dropdown -->
                      <div class="flex-inner">
                        <actions-dropdown
                          v-if="canEdit && !statusOfCompleted"
                          ref="actionsDropdown"
                          :entity="promotionalActivity"
                          @edit="onEditPromotionalActivity"
                          @copy="onCopyPromotionalActivity"
                          @delete="onDeletePromotionalActivity"
                        />
                      </div>
                    <!-- /Actions Dropdown -->

                    </div>
                    <!-- /Card Top -->

                    <!-- Card Bottom -->
                    <div class="card-bottom">
                      <p class="pre-formatted">
                        {{ promotionalActivity.description }}
                      </p>
                    </div>
                  <!-- /Card Bottom -->
                  </div>
                </el-col>
              </ib-draggable>
              <!-- /List Items -->
            </el-row>
            <!-- Show All -->
            <el-row v-if="promotions.length > 5">
              <el-col>
                <show-all-toggle
                  :show="showAllPromotionalActivitiesList"
                  @click="showAllPromotionalActivitiesList = !showAllPromotionalActivitiesList"
                />
              </el-col>
            </el-row>
            <!-- /Show All -->
          </div>
          <!-- /List Wrapper -->

        </div>
        <!-- /Promotion -->

      </div>
      <!-- /Content -->

      <!-- Add More Details -->
      <add-more-details
        :category="'concept'"
        :step="'promotion'"
        :concept-name="'promotionMoreDetails'"
        :completed-step-name="'promotionCompleted'"
      />
      <!-- /Add More Details -->

    </div>
    <!-- /Container -->

    <!-- Main Bottom Bar -->
    <main-bottom-bar
      :back="backNavigation"
      :next="nextNavigation"
      :completed-step-name="'promotionCompleted'"
      :step-filled="promotions.length > 0"
      @complete-step="onCompleteStep"
    />
    <!-- /Main Bottom Bar -->

    <!-- Info Guide Drawer -->
    <info-guide-drawer
      type="promotionExamples"
      :visible="guideVisible"
      @close="guideVisible = false"
    />
    <!-- /Info Guide Drawer -->

    <!-- Dialog Add Promotional Activity -->
    <dialog-add-promotional-activity
      :dialog-add-promotional-activity-visible="dialogAddPromotionalActivityVisible"
      :promotional-activity-action="promotionalActivityAction"
      :options="options"
      @close-dialog-add-promotional-activity="onCloseDialogAddPromotionalActivity"
    />
    <!-- /Dialog Add Promotional Activity -->

    <!-- Dialog Process Completed -->
    <dialog-process-completed
      :dialog-process-completed-visible="dialogProcessCompletedVisible"
      @close-dialog-process-completed="onCloseDialogProcessCompleted"
    />
    <!-- /Dialog Process Completed -->

    <!-- Dialog Remove Examples -->
    <dialog-remove-examples
      :visible="dialogRemoveExamplesVisible"
      :loading="removeExamplesLoading"
      @no="dialogRemoveExamplesVisible = false"
      @yes="deleteExamples"
    />
    <!-- /Dialog Remove Examples -->

  </div>
  <!-- /Promotion -->

</template>

<script>
import ActionsDropdown from '@/views/Home/StoryMode/Components/ActionsDropdown'
import AddMoreDetails from '@/views/Home/StoryMode/Components/AddMoreDetails'
import AddNewCard from '@/components/_v2/AddNewCard'
import AiBuddyDrawer from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyDrawer.vue'
import BusinessGuideHeader from '@/components/_v2/Common/BusinessGuideHeader'
import BusinessGuideSearch from '@/views/Home/Components/BusinessGuideSearch.vue'
import BusinessPlanAdditionalInfo from '@/views/Home/StoryMode/Components/BusinessPlanAdditionalInfo.vue'
import CompletedOverlay from '@/views/Home/StoryMode/Components/CompletedOverlay'
import DashboardHeader from '@/views/Home/StoryMode/Components/DashboardHeader'
import DialogAddPromotionalActivity from './Dialogs/DialogAddPromotionalActivity'
import DialogProcessCompleted from '@/views/Home/StoryMode/Components/Dialogs/DialogProcessCompleted'
import DialogRemoveExamples from '@/views/Home/StoryMode/Components/DialogRemoveExamples'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import MainBottomBar from '@/views/Home/StoryMode/Components/MainBottomBar/MainBottomBar'
import MainNavigation from '@/views/Home/StoryMode/Components/MainNavigation/MainNavigation'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinEraseTemplate from '@/mixins/eraseTemplate'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinProcessCompleted from '@/mixins/processCompleted'
import ShowAllToggle from '@/views/Home/StoryMode/Components/ShowAllToggle'
import TutorialTooltip from '@/views/Home/Components/TutorialTooltip'
import ViewSwitcher from '@/components/_v2/ViewSwitcher'

import { mapActions, mapGetters } from 'vuex'
import { getImageBySize } from '@/helpers/imageSizeHelper'
import { promotionTypes } from '@/data/promotionTypes'

import InfoGuide from '@/views/Home/StoryMode/Components/Example/InfoGuide.vue'
import InfoGuideDrawer from '@/views/Home/StoryMode/Components/Example/InfoGuideDrawer.vue'

export default {
  name: 'StoryModePromotion',

  components: {
    InfoGuide,
    AiBuddyDrawer,
    InfoGuideDrawer,
    BusinessPlanAdditionalInfo,
    BusinessGuideSearch,
    ActionsDropdown,
    AddMoreDetails,
    AddNewCard,
    BusinessGuideHeader,
    CompletedOverlay,
    DashboardHeader,
    DialogAddPromotionalActivity,
    DialogProcessCompleted,
    DialogRemoveExamples,
    EraseIconButton,
    MainBottomBar,
    MainNavigation,
    ShowAllToggle,
    TutorialTooltip,
    ViewSwitcher
  },

  mixins: [
    MixinProcessCompleted,
    MixinGuide,
    MixinDeleteIdeaExamples,
    MixinIdeaRoles,
    MixinEraseTemplate
  ],

  data () {
    return {
      aiBuddyDrawerVisible: false,
      searchValue: '',
      removeExamplesLoading: false,
      dialogRemoveExamplesVisible: false,
      dialogAddPromotionalActivityVisible: false,
      guideVisible: false,
      promotionalActivityAction: null,
      showAllPromotionalActivitiesGrid: false,
      showAllPromotionalActivitiesList: false,
      dialogProcessCompletedVisible: false,
      options: {
        types: promotionTypes
      }
    }
  },

  computed: {
    ...mapGetters('idea', [
      'getPromotions',
      'getStepIsCompleted',
      'getHasItemsFromTemplate'
    ]),

    promotions () {
      return this.getPromotions
    },

    statusOfCompleted () {
      return this.getStepIsCompleted('promotionCompleted')
    },

    isPromotionalActivitiesEmpty () {
      return !this.promotions.length
    },

    promotionalActivitiesToShowGrid () {
      if (this.searchValue !== '' && this.searchValue) {
        return this.filterPromotions
      }

      return this.showAllPromotionalActivitiesGrid === true
        ? this.promotions
        : this.promotions.slice(0, 4)
    },

    promotionalActivitiesToShowList () {
      if (this.searchValue !== '' && this.searchValue) {
        return this.filterPromotions
      }

      return this.showAllPromotionalActivitiesList === true
        ? this.promotions
        : this.promotions.slice(0, 5)
    },

    filterPromotions () {
      return this.promotions.filter(item => item.name.toUpperCase().includes(this.searchValue.toUpperCase()))
    },

    removeButtonVisible () {
      return this.canEdit && this.getHasItemsFromTemplate('getPromotions')
    },

    hasNotAccess () {
      return !this.canEdit || this.statusOfCompleted
    },

    backNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.product'),
        route: 'business-guide-concept-product'
      }
    },

    nextNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.distribution'),
        route: 'business-guide-concept-distribution'
      }
    }
  },

  created () {
    this.openGuide('promotionGuide')
    this.getPromotion()
  },

  methods: {
    ...mapActions('idea', [
      'getPromotion',
      'deleteStoryModePromotionalActivity',
      'deleteIdeaExamples',
      'updateOrderNumber'
    ]),

    onStartPromotionOrder () {
      this.$refs.actionsDropdown.forEach(dropdown => {
        dropdown.$refs.itemDropdown.visible = false
      })
    },

    onChangePromotionOrder (data) {
      this.updateOrderNumber({
        type: 'promotion',
        data,
        oldOrderData: this.promotions,
        mutation: 'setPromotionalActivity'
      })
    },

    onEraseFromTemplate () {
      if (this.statusOfCompleted) {
        this.openedDialogProcessCompleted()

        return
      }
      this.dialogRemoveExamplesVisible = true
    },

    onCloseDialogAddPromotionalActivity () {
      this.dialogAddPromotionalActivityVisible = false
      this.promotionalActivityAction = null

      // Workaround to update computed property promotionalActivitiesToShowGrid & promotionalActivitiesToShowList
      this.updatePromotionalActivitiesView()
    },

    checkPromotionCard (promotionalActivity) {
      if (!this.canEdit) return
      if (this.statusOfCompleted) {
        this.dialogProcessCompletedVisible = true

        return
      }
      if (promotionalActivity.id) {
        this.onEditPromotionalActivity(promotionalActivity)
      }
    },

    checkPromotionCardVisible () {
      if (!this.canEdit) return
      if (this.statusOfCompleted) {
        this.dialogProcessCompletedVisible = true
      } else {
        this.dialogAddPromotionalActivityVisible = true
      }
    },

    updatePromotionalActivitiesView () {
      this.showAllPromotionalActivitiesList = !this.showAllPromotionalActivitiesList
      this.showAllPromotionalActivitiesList = !this.showAllPromotionalActivitiesList
      this.showAllPromotionalActivitiesGrid = !this.showAllPromotionalActivitiesGrid
      this.showAllPromotionalActivitiesGrid = !this.showAllPromotionalActivitiesGrid
    },

    onActivateView (view) {
      this.$store.commit('itemsView/setPromotionView', view)
    },

    onEditPromotionalActivity (promotionalActivity) {
      this.promotionalActivityAction = Object.assign({}, promotionalActivity)
      this.dialogAddPromotionalActivityVisible = true
    },

    onCopyPromotionalActivity (promotionalActivity) {
      this.promotionalActivityAction = Object.assign({}, promotionalActivity)
      this.promotionalActivityAction.id = 0
      this.dialogAddPromotionalActivityVisible = true
    },

    onDeletePromotionalActivity (promotionalActivity) {
      this.deleteStoryModePromotionalActivity(promotionalActivity.id)

      // Return to grid view, if there are no promotional activities
      if (this.$store.state.idea.storyMode.concept.promotion.promotionalActivities.length === 0) {
        this.onActivateView('grid')
      }
    },

    cardImageBySize (image, size) {
      return image ? 'background-image: url(' + getImageBySize(image.sizes, size) + ')' : ''
    },

    onGuideExampleVisible () {
      this.guideVisible = !this.guideVisible
    },

    deleteExamples () {
      this.removeExamplesLoading = true
      this.deleteIdeaExamples('promotions')
        .then(response => {
          const { type, ids } = response
          this.deleteFromState({ type, ids })
          this.dialogRemoveExamplesVisible = false
          this.removeExamplesLoading = false
        }).catch(_ => { this.removeExamplesLoading = false })
    }
  }
}
</script>

<style scoped>
  #story-mode-promotion {
    margin-left: 0;
  }
</style>
